import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RadioButtonCircle } from "./RadioButtonCircle";
import { setSelectedBrand, updateBrand } from "../store/brandsSlice";
import { Icon } from "./Icon";
import { useNavigate } from "react-router-dom";
import { TextInputNew } from "./TextInputNew";
import { StatusTag } from "./StatusTag";
import styled from "@emotion/styled";
import LoadingButton from "@mui/lab/LoadingButton";
import { showSnackBar } from "store/uiSlice";
import { FileUpload } from "./FileUpload";
import { colors } from "styles/colors";
import { LogoUploader } from "./LogoUploader";
import * as Sentry from "@sentry/react";
import { AddMCCs } from "./AddMCCs";
import { AddMIDs } from "./AddMIDs";

const BrandRadioButton = ({ brand, selected, onClick }) => {
	return (
		<Box
			sx={{
				"display": "flex",
				"justifyContent": "space-between",
				"alignItems": "center",
				"height": "65px",
				"backgroundColor": selected ? "#EEF6F4" : "white.main",
				"borderRadius": "10px",
				"pl": "16px",
				"pr": "25px",
				"mb": "24px",
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={onClick}
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img
					src={brand.picture}
					style={{
						width: "48px",
						height: "48px",
						borderRadius: "100px",
						border: "1px solid #EFF0F6",
						marginRight: "26px",
						background: colors.white,
						objectFit: "cover",
					}}
					alt={`${brand?.name || "Brand"} logo`}
				/>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: selected ? "#2C2C2C" : "#4D4D4D",
					}}
				>
					{brand?.name || "Brand"}
				</Typography>
			</Box>
			<RadioButtonCircle selected={selected} />
		</Box>
	);
};

export const BrandSettings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		brands,
		selectedBrand,
		isLoadingBrand: isUpdateBrandLoading,
	} = useSelector((state) => state.brands);

	const user = useSelector((state) => state.settings.userDetails);

	const [isEditing, setIsEditing] = useState(false);
	const [hasChanged, setHasChanged] = useState({
		domain: false,
		certificate: false,
		vatCertificate: false,
		picture: false,
		MIDs: false,
		MCCs: false,
	});

	// Brand Domain
	const [domain, setDomain] = useState(
		selectedBrand?.domain?.name ? selectedBrand.domain : null,
	);

	// Commercial Certificate
	const [certificatePdf, setCertificatePdf] = useState(
		selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
	);
	const [uploadProgress, setUploadProgress] = useState(
		selectedBrand?.certificate?.name ? 100 : 0,
	);

	// VAT Certificate
	const [vatCertificateFile, setVatCertificateFile] = useState(
		selectedBrand?.vatCertificate?.name ? selectedBrand.vatCertificate : null,
	);
	const [uploadVatProgress, setUploadVatProgress] = useState(
		selectedBrand?.vatCertificate?.name ? 100 : 0,
	);

	// MCC (Merchant Category Code)
	const [MCCs, setMCCs] = useState(
		selectedBrand?.MCCs?.length
			? selectedBrand?.MCCs?.map((mcc) => ({
					id: Date.now() + Math.random(), // Added ID to each Array Item to avoid unncessary rerenders
					value: mcc,
				}))
			: [{ id: Date.now() + Math.random(), value: "" }],
	);

	// MID (Merchant ID)
	const [MIDs, setMIDs] = useState(
		selectedBrand?.MIDs?.length
			? selectedBrand?.MIDs?.map((mcc) => ({
					id: Date.now() + Math.random(), // Added ID to each Array Item to avoid unncessary rerenders
					value: mcc,
				}))
			: [{ id: Date.now() + Math.random(), value: "" }],
	);

	const handleChangeMIDs = (newValue) => {
		setMIDs(newValue);
		setHasChanged((prev) => {
			return {
				...prev,
				MIDs: true,
			};
		});
	};

	const handleChangeMCCs = (newValue) => {
		setMCCs(newValue);
		setHasChanged((prev) => {
			return {
				...prev,
				MCCs: true,
			};
		});
	};

	// Brand Picture (Logo)
	const [brandPicture, setBrandPicture] = useState(selectedBrand?.picture);

	// ---------- HANDLERS ----------

	// Domain
	const handleDomainChange = (event) => {
		setDomain((prev) => ({
			...prev,
			name: event.target.value,
		}));
		setHasChanged((prev) => ({
			...prev,
			domain: true,
		}));
	};

	// Commercial Certificate
	const handleCertificatePdfChange = (newCertificatePdf) => {
		setCertificatePdf(newCertificatePdf);
		setUploadProgress(0);
		setHasChanged((prev) => ({
			...prev,
			certificate: true,
		}));
	};

	// VAT Certificate
	const handleVatCertificateChange = (newVatCert) => {
		setVatCertificateFile(newVatCert);
		setUploadVatProgress(0);
		setHasChanged((prev) => ({
			...prev,
			vatCertificate: true,
		}));
	};

	// Picture (Logo)
	const handlePictureChange = (imageFile) => {
		setBrandPicture(imageFile);
		setHasChanged((prev) => ({
			...prev,
			picture: true,
		}));
	};

	// Switch to Edit Mode
	const handleEditMode = () => {
		setIsEditing(true);
	};

	// Cancel Changes
	const handleCancel = () => {
		setMCCs(
			selectedBrand?.MCCs?.length
				? selectedBrand?.MCCs?.map((mcc) => ({
						id: Date.now() + Math.random(),
						value: mcc,
					}))
				: [{ id: Date.now() + Math.random(), value: "" }],
		);
		setMIDs(
			selectedBrand?.MIDs?.length
				? selectedBrand?.MIDs?.map((mid) => ({
						id: Date.now() + Math.random(),
						value: mid,
					}))
				: [{ id: Date.now() + Math.random(), value: "" }],
		);
		setDomain(selectedBrand?.domain?.name ? selectedBrand.domain : null);
		setCertificatePdf(
			selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
		);
		setVatCertificateFile(
			selectedBrand?.vatCertificate?.name ? selectedBrand.vatCertificate : null,
		);
		setBrandPicture(selectedBrand?.picture);
		setIsEditing(false);
		setHasChanged({
			domain: false,
			certificate: false,
			vatCertificate: false,
			picture: false,
		});
		setUploadProgress(selectedBrand?.certificate?.name ? 100 : 0);
		setUploadVatProgress(selectedBrand?.vatCertificate?.name ? 100 : 0);
	};

	// Save Changes
	const handleSave = async () => {
		const formData = new FormData();
		formData.append("id", selectedBrand.id);

		// Domain
		if (domain && hasChanged.domain) {
			formData.append("domain", domain?.name);
			formData.append("domainApprovalId", domain.approvalId);
		}

		// Commercial Certificate
		if (certificatePdf && hasChanged.certificate) {
			formData.append("certificatePdf", certificatePdf);
		}

		// VAT Certificate
		if (vatCertificateFile && hasChanged.vatCertificate) {
			formData.append("vatCertificatePdf", vatCertificateFile);
		}

		// Picture
		if (brandPicture && brandPicture.file && hasChanged.picture) {
			formData.append("picture", brandPicture.file);
		}

		const MIDstring = MIDs.filter((mid) => mid.value.trim() !== "")
			.map((mid) => mid.value)
			.join(",");

		const MCCstring = MCCs.filter((mcc) => mcc.value.trim() !== "")
			.map((mcc) => mcc.value)
			.join(",");

		formData.append("MCCs", MCCstring);
		formData.append("MIDs", MIDstring);

		try {
			await dispatch(
				updateBrand({
					updatedBrand: formData,
					onUploadProgress: (progressEvent) => {
						const progress = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total,
						);
						setUploadProgress(progress);
						setUploadVatProgress(progress);
					},
				}),
			).unwrap();

			setIsEditing(false);
			setHasChanged({
				domain: false,
				certificate: false,
				vatCertificate: false,
				picture: false,
				MCCs: false,
				MIDs: false,
			});
			dispatch(
				showSnackBar({
					text: `${selectedBrand?.name} updated successfully!`,
					severity: "success",
				}),
			);
		} catch (error) {
			Sentry.captureException(error, {
				tags: {
					area: "BrandSettings",
					action: "updateBrand",
				},
			});
		}
	};

	const handleGoAddBrand = () => {
		navigate("create-brand");
	};

	// Decide if Save button is disabled
	const handleButtonDisableStatus = () => {
		const isAnyChanged =
			hasChanged.domain ||
			hasChanged.certificate ||
			hasChanged.vatCertificate ||
			hasChanged.picture ||
			hasChanged.MCCs ||
			hasChanged.MIDs;
		const isDomainValid = domain && domain.name;
		const isCertificateValid = certificatePdf;
		const isVatCertificateValid = vatCertificateFile;
		const isPictureValid = brandPicture;
		const isMCCsValid = hasChanged.MCCs
			? MCCs.some((mcc) => mcc.value.trim() !== "")
			: true;
		const isMIDsValid = hasChanged.MIDs
			? MIDs.some((mid) => mid.value.trim() !== "")
			: true;

		return !(
			isAnyChanged &&
			isDomainValid &&
			isCertificateValid &&
			isVatCertificateValid &&
			isPictureValid &&
			isMCCsValid &&
			isMIDsValid
		);
	};

	// On brand change, reset local states
	useEffect(() => {
		setMCCs(
			selectedBrand?.MCCs?.length
				? selectedBrand?.MCCs?.map((mcc) => ({
						id: Date.now() + Math.random(),
						value: mcc,
					}))
				: [{ id: Date.now() + Math.random(), value: "" }],
		);
		setMIDs(
			selectedBrand?.MIDs?.length
				? selectedBrand?.MIDs?.map((mid) => ({
						id: Date.now() + Math.random(),
						value: mid,
					}))
				: [{ id: Date.now() + Math.random(), value: "" }],
		);
		setDomain(selectedBrand?.domain?.name ? selectedBrand.domain : null);
		setCertificatePdf(
			selectedBrand?.certificate?.name ? selectedBrand.certificate : null,
		);
		setVatCertificateFile(
			selectedBrand?.vatCertificate?.name ? selectedBrand.vatCertificate : null,
		);
		setBrandPicture(selectedBrand?.picture);
		setIsEditing(false);
		setHasChanged({
			domain: false,
			certificate: false,
			vatCertificate: false,
			picture: false,
			MCCs: false,
			MIDs: false,
		});
		setUploadProgress(selectedBrand?.certificate?.name ? 100 : 0);
		setUploadVatProgress(selectedBrand?.vatCertificate?.name ? 100 : 0);
	}, [selectedBrand]);

	return (
		<Box
			sx={{
				display: "grid",
				gridTemplateRow: "1fr 1fr",
				gap: "24px",
			}}
		>
			{/* ----- Top Panel: Brand Update ----- */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				{/* Brand Picture */}
				<Box
					sx={{
						width: "100px",
						height: "100px",
						borderRadius: "100px",
						border: "1px solid #EFF0F6",
						marginBottom: "16px",
						position: "relative",
					}}
				>
					{isEditing ? (
						<LogoUploader
							logo={brandPicture}
							setLogo={handlePictureChange}
							edit={isEditing}
							containerStyle={{
								width: "100%",
								height: "100%",
							}}
						/>
					) : (
						<img
							src={brandPicture}
							alt={`${selectedBrand?.name || "Brand"} logo`}
							style={{
								height: "100%",
								width: "100%",
								borderRadius: "50%",
								border: "1px solid #EFF0F6",
								backgroundColor: colors.white,
								boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
								objectFit: "cover",
							}}
						/>
					)}
				</Box>

				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "20px",
						lineHeight: "24px",
						color: "#2C2C2C",
					}}
				>
					{selectedBrand?.name}
				</Typography>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: isEditing ? "#4D4D4D" : " #9A9A9A",
							marginBottom: isEditing ? "3px" : "5px",
						}}
					>
						Merchant ID
					</Typography>
					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									lineHeight: "14px",
									color: " #4D4D4D",
								}}
							>
								Add Merchant ID here
							</Typography>
							<AddMIDs
								MIDs={MIDs}
								setMIDs={handleChangeMIDs}
								inputStyle={{
									width: "30%",
									marginTop: "16px",
								}}
								showLabelOnFirst={false}
							/>
						</>
					) : (
						MIDs.map((mid, i) => (
							<Typography
								key={`${mid.id}-not-editable`}
								sx={{
									fontSize: "16px",
									lineHeight: "19px",
									color: "#2C2C2C",
									mb: i === MIDs.length - 1 ? 0 : "3px",
								}}
							>
								{mid.value}
							</Typography>
						))
					)}
				</Box>

				{/* --- Commercial Registration Certificate --- */}
				<Box sx={{ marginTop: "24px" }}>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						sx={{ mb: isEditing ? "3px" : "5px" }}
					>
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								fontWeight: "500",
								color: isEditing ? "#4D4D4D" : " #9A9A9A",
								marginRight: "5px",
							}}
						>
							Commercial Registration Certificate
						</Typography>
						{!isEditing ? (
							<StatusTag statusId={certificatePdf?.approvalId} />
						) : (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Icon name="info" style={{ fontSize: 16, color: "#61AEA0" }} />
								<Typography
									sx={{
										fontSize: "14px",
										lineHeight: "17px",
										fontWeight: "500",
										color: "#61AEA0",
										marginLeft: "4px",
									}}
								>
									PDF file will be uploaded when you save changes!
								</Typography>
							</Box>
						)}
					</Grid>

					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									lineHeight: "14px",
									fontWeight: "400",
									color: "#4D4D4D",
									marginTop: "5px",
									display: "flex",
									alignItems: "center",
									gap: "8px",
								}}
							>
								Upload your CR to get verified
							</Typography>
							<Box sx={{ width: "30%", marginTop: "16px" }}>
								<FileUpload
									uploadProgress={uploadProgress}
									file={certificatePdf}
									onChangeFile={handleCertificatePdfChange}
								/>
							</Box>
						</>
					) : (
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								color: "#2C2C2C",
							}}
						>
							{certificatePdf?.name}
						</Typography>
					)}
				</Box>

				{/* --- VAT Registration Certificate --- */}
				<Box sx={{ marginTop: "24px" }}>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						sx={{ mb: isEditing ? "3px" : "5px" }}
					>
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								fontWeight: "500",
								color: isEditing ? "#4D4D4D" : " #9A9A9A",
								marginRight: "5px",
							}}
						>
							VAT Registration Certificate
						</Typography>
						{!isEditing ? (
							<StatusTag statusId={vatCertificateFile?.approvalId} />
						) : (
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Icon name="info" style={{ fontSize: 16, color: "#61AEA0" }} />
								<Typography
									sx={{
										fontSize: "14px",
										lineHeight: "17px",
										fontWeight: "500",
										color: "#61AEA0",
										marginLeft: "4px",
									}}
								>
									PDF file will be uploaded when you save changes!
								</Typography>
							</Box>
						)}
					</Grid>

					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									lineHeight: "14px",
									fontWeight: "400",
									color: "#4D4D4D",
									marginTop: "5px",
								}}
							>
								Upload your VAT certificate to get verified
							</Typography>
							<Box sx={{ width: "30%", marginTop: "16px" }}>
								<FileUpload
									uploadProgress={uploadVatProgress}
									file={vatCertificateFile}
									onChangeFile={handleVatCertificateChange}
								/>
							</Box>
						</>
					) : (
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								color: "#2C2C2C",
							}}
						>
							{vatCertificateFile?.name}
						</Typography>
					)}
				</Box>

				{/* --- Brand Domain --- */}
				<Box sx={{ marginTop: "24px" }}>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						sx={{ mb: isEditing ? "3px" : "5px" }}
					>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: "500",
								lineHeight: "19px",
								color: isEditing ? "#4D4D4D" : " #9A9A9A",
								marginRight: "5px",
							}}
						>
							Brand Domain
						</Typography>
						{!isEditing && <StatusTag statusId={domain?.approvalId} />}
					</Grid>
					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									fontWeight: "400",
									lineHeight: "14px",
									color: "#4D4D4D",
									marginTop: "5px",
								}}
							>
								Add your brand domain link here
							</Typography>
							<TextInputNew
								value={domain?.name || ""}
								placeholder={domain?.name || "Please enter domain name"}
								name="domain"
								onChange={handleDomainChange}
								disabled={!isEditing}
								containerStyle={{
									width: "30%",
									marginTop: "16px",
								}}
							/>
						</>
					) : (
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								color: "#2C2C2C",
							}}
						>
							{domain?.name}
						</Typography>
					)}
				</Box>

				{/* --- Account Portfolio --- */}
				<Box sx={{ marginTop: "24px" }}>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: isEditing ? "#4D4D4D" : " #9A9A9A",
							marginBottom: isEditing ? "3px" : "5px",
						}}
					>
						Account Portfolio
					</Typography>
					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									lineHeight: "14px",
									color: "#4D4D4D",
								}}
							>
								view your account type plan
							</Typography>
							<TextInputNew
								disabled
								value={user?.accountPortfolio}
								containerStyle={{
									width: "30%",
									marginTop: "16px",
								}}
							/>
						</>
					) : (
						<Typography
							sx={{
								fontSize: "16px",
								lineHeight: "19px",
								color: "#2C2C2C",
							}}
						>
							{user?.accountPortfolio}
						</Typography>
					)}
				</Box>
				<Box
					sx={{
						marginTop: "24px",
					}}
				>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: isEditing ? "#4D4D4D" : " #9A9A9A",
							marginBottom: isEditing ? "3px" : "5px",
						}}
					>
						Merchant Category Code
					</Typography>

					{isEditing ? (
						<>
							<Typography
								sx={{
									fontSize: "12px",
									lineHeight: "14px",
									color: " #4D4D4D",
								}}
							>
								Add your Merchant Category Code here
							</Typography>
							<AddMCCs
								MCCs={MCCs}
								setMCCs={handleChangeMCCs}
								inputStyle={{
									width: "30%",
									marginTop: "16px",
								}}
								showLabelOnFirst={false}
							/>
						</>
					) : (
						MCCs.map((mcc, i) => (
							<Typography
								key={`${mcc.id}-not-editable`}
								sx={{
									fontSize: "16px",
									lineHeight: "19px",
									color: "#2C2C2C",
									mb: i === MCCs.length - 1 ? 0 : "3px",
								}}
							>
								{mcc.value}
							</Typography>
						))
					)}
				</Box>

				{/* --- Edit/Save Buttons --- */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						marginTop: "24px",
					}}
				>
					{!isEditing ? (
						<Button
							variant="contained"
							color="primary"
							onClick={handleEditMode}
							sx={{
								width: "104px",
								height: "40px",
								borderRadius: "10px",
								textTransform: "none",
							}}
						>
							Edit
						</Button>
					) : (
						<>
							<Button
								variant="outlined"
								onClick={handleCancel}
								sx={{
									width: "104px",
									height: "40px",
									borderRadius: "10px",
									mr: "10px",
									textTransform: "none",
								}}
							>
								Cancel
							</Button>
							<LoadingButton
								loading={isUpdateBrandLoading}
								variant="contained"
								color="primary"
								onClick={handleSave}
								disabled={handleButtonDisableStatus()}
								sx={{
									width: "188px",
									height: "40px",
									borderRadius: "10px",
									textTransform: "none",
								}}
							>
								Save changes
							</LoadingButton>
						</>
					)}
				</Box>
			</Box>

			{/* ----- Bottom Panel: Brands List & Switcher ----- */}
			<Box
				sx={{
					p: "24px",
					border: "1px solid #EFF0F6",
					borderRadius: "20px",
					backgroundColor: "white.main",
					boxShadow: "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
			>
				<Typography
					sx={{
						fontWeight: "500",
						fontSize: "16px",
						lineHeight: "19px",
						color: "#4D4D4D",
						mb: "3px",
					}}
				>
					Brands
				</Typography>
				<Typography
					sx={{
						fontSize: "12px",
						lineHeight: "14px",
						color: "#4D4D4D",
						mb: "24px",
					}}
				>
					switch brand or add a new one
				</Typography>
				{brands.map((brand) => (
					<BrandRadioButton
						key={`switch-brand-${brand.id}`}
						brand={brand}
						selected={brand.id === selectedBrand?.id}
						onClick={() => dispatch(setSelectedBrand(brand))}
					/>
				))}
				<Box
					sx={{
						"display": "flex",
						"alignItems": "center",
						"height": "65px",
						"borderRadius": "10px",
						"&:hover": { cursor: "pointer" },
						"px": "16px",
					}}
					onClick={handleGoAddBrand}
				>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						sx={{
							width: "48px",
							height: "48px",
							borderRadius: "50%",
							backgroundColor: "#e5eaee",
							boxShadow: "0 10px 20px 0 rgba(17, 110, 95, 0.05)",
							mr: "26px",
						}}
					>
						<Icon name="plusOffer" style={{ fontSize: 16 }} />
					</Box>
					<Typography
						sx={{
							fontWeight: "500",
							fontSize: "16px",
							lineHeight: "19px",
							color: "#4D4D4D",
						}}
					>
						Add Brand
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
