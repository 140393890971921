import React, { useEffect, useMemo } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchCatalogs, fetchOffers, setNewOffer } from "../store/offersSlice";
import { Screen } from "../components/Screen";
import { OffersList } from "../components/OffersList";

export const Offers = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const offers = useSelector(({ offers }) => offers.offers);
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const selectedBrand = useSelector((state) => state.brands.selectedBrand);
	const selectedBrandID = useSelector((state) => state.brands.selectedBrandID);
	const loadingOffers = useSelector(({ offers }) => offers.loadingOffers);

	const isFirstOfferCreation = useMemo(() => {
		if (!selectedBrand) {
			return true;
		}

		const { MCCs, MIDs } = selectedBrand;

		return !MCCs.length || !MIDs.length;
	}, [selectedBrand]);

	useEffect(() => {
		if (!selectedBrandID) {
			return;
		}

		dispatch(fetchOffers());
	}, [selectedBrandID]);

	useEffect(() => {
		if (!catalogs) {
			dispatch(fetchCatalogs());
		}
	}, [catalogs]);

	const handlePressAddOffer = () => {
		if (isFirstOfferCreation) {
			navigate("add-mid-and-mcc");
			return;
		}

		dispatch(setNewOffer({}));
		navigate("create-offer-general");
	};

	return (
		<Screen title={"Offers"}>
			<Typography
				sx={{
					fontWeight: "500",
					fontSize: 20,
					lineHeight: "24px",
					marginBottom: "24px",
				}}
			>
				Your Offers
			</Typography>
			<OffersList
				offers={offers}
				loading={loadingOffers}
				handlePressAddOffer={handlePressAddOffer}
				catalogs={catalogs}
				disableOfferDetails={false}
			/>
			<Outlet />
		</Screen>
	);
};
