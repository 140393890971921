import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "./Icon";
import { TextInputNew } from "./TextInputNew";

export const AddMCCs = ({
	MCCs,
	setMCCs,
	inputStyle = {},
	showLabelOnFirst = true,
	containerStyle = {},
}) => {
	const handleAddMCC = () => {
		const newMCC = { id: Date.now(), value: "" };
		setMCCs((prev) => [...prev, newMCC]);
	};

	const handleChangeMCC = (value, id) => {
		setMCCs((prev) =>
			prev.map((mcc) => (mcc.id === id ? { ...mcc, value } : mcc)),
		);
	};

	const handleClearMCC = (id) => {
		setMCCs((prev) => prev.filter((mcc) => mcc.id !== id));
	};

	return (
		<Box sx={containerStyle}>
			{MCCs.map((mcc, i) => (
				<TextInputNew
					key={mcc.id}
					value={mcc.value}
					label={showLabelOnFirst && i === 0 ? "Merchant Category Code" : null}
					placeholder={
						i === 0 && showLabelOnFirst ? null : "Merchant Category Code"
					}
					onChange={(e) => handleChangeMCC(e.target.value, mcc.id)}
					containerStyle={{ ...inputStyle, mb: "10px" }}
					onClear={i !== 0 ? () => handleClearMCC(mcc.id) : null}
				/>
			))}
			<Box
				sx={{
					"display": "flex",
					"alignItems": "center",
					"&:hover": { cursor: "pointer" },
				}}
				onClick={handleAddMCC}
			>
				<Icon
					name={"plusCircle"}
					style={{
						width: "15px",
						height: "15px",
					}}
				/>
				<Typography
					sx={{
						ml: "5px",
						fontSize: "12px",
						lineHeight: "14px",
						color: "#030229",
					}}
				>
					Add MCC
				</Typography>
			</Box>
		</Box>
	);
};
