import validate from "validate.js";

const offerConstraints = {
	name: {
		presence: {
			allowEmpty: false,
			message: "^Name is required",
		},
	},
	age: {
		presence: {
			allowEmpty: false,
			message: "^Age is required",
		},
		length: {
			minimum: 1,
			message: "^At least one age must be selected",
		},
	},
	typeOfClient: {
		presence: {
			allowEmpty: false,
			message: "^Type is required",
		},
		length: {
			minimum: 1,
			message: "^At least one type must be selected",
		},
	},
	budget: {
		presence: {
			allowEmpty: false,
			message: "^Budget is required",
		},
		numericality: {
			greaterThan: 0,
			message: "^Budget must be greater than 0",
		},
	},
	discount: {
		presence: {
			allowEmpty: false,
			message: "^Discount is required",
		},
		numericality: {
			greaterThan: 0,
			message: "^Discount must be greater than 0",
		},
	},
	limitAmountMax: {
		presence: {
			allowEmpty: false,
			message: "^Limit Amount Max is required",
		},
		numericality: {
			greaterThan: 0,
			message: "^Limit Amount Max must be greater than 0",
		},
	},
	limitAmountMin: {
		presence: {
			allowEmpty: false,
			message: "^Limit Amount Min is required",
		},
		numericality: {
			greaterThan: 0,
			message: "^Limit Amount Min must be greater than 0",
		},
	},
	limitUses: {
		presence: {
			allowEmpty: false,
			message: "^Limit Uses is required",
		},
		numericality: {
			greaterThan: 0,
			message: "^Limit Uses must be greater than 0",
		},
	},
	returnPeriod: {
		presence: {
			allowEmpty: false,
			message: "^Return Period is required",
		},
		numericality: {
			greaterThanOrEqualTo: 0,
			message: "^Return Period must be greater than or equal 0",
		},
	},
};

const signInConstraints = {
	email: {
		presence: { allowEmpty: false, message: "is required" },
		email: {
			message: "^Invalid email format. Please enter a valid email address",
		},
	},
};

const signUpUserConstraints = {
	firstName: {
		presence: { allowEmpty: false, message: "is required" },
		format: {
			pattern: "^[a-zA-Z ]+$",
			message: "must contain only letters and spaces",
		},
		length: {
			minimum: 2,
			maximum: 20,
			message: "must be 2-20 characters long",
		},
	},
	lastName: {
		presence: { allowEmpty: false, message: "is required" },
		format: {
			pattern: "^[a-zA-Z ]+$",
			message: "must contain only letters and spaces",
		},
		length: {
			minimum: 2,
			maximum: 20,
			message: "must be 2-20 characters long",
		},
	},
	email: {
		presence: { allowEmpty: false, message: "is required" },
		email: {
			message: "^Invalid email format. Please enter a valid email address",
		},
	},
	phone: {
		presence: { allowEmpty: false, message: "is required" },
		format: {
			pattern: "\\+?\\d+",
			flags: "i",
			message: "^Please enter a valid phone number",
		},
	},
	password: {
		presence: { allowEmpty: false, message: "is required" },
		length: {
			minimum: 8,
			maximum: 20,
			message: "must be between 8-20 characters",
		},
		format: {
			pattern:
				"(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}",
			message:
				"must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
		},
	},
	confirmPassword: {
		presence: {
			allowEmpty: false,
			message: "is required",
		},
		equality: {
			attribute: "password",
			message: "^Passwords do not match",
		},
	},
};

const createBrandConstraints = {
	name: {
		presence: { allowEmpty: false, message: "^Please enter the brand name" },
		length: {
			maximum: 50,
			message: "^The brand name cannot exceed 50 characters",
		},
	},
	domain: {
		presence: { allowEmpty: false, message: "^Please enter the brand domain" },
		format: {
			pattern:
				"^(https?:\\/\\/www\\.|https?:\\/\\/|www\\.)[a-zA-Z0-9-]+\\.(com|org|net|edu|gov|mil|co|io|[a-zA-Z]{2})$",
			message:
				"^Please enter a valid URL starting with 'www.' or 'https://', and ending with a valid TLD",
		},
	},
	categoryId: {
		presence: {
			allowEmpty: false,
			message: "^Please select a brand category from the list",
		},
	},
};

const createBankTransferConstraints = {
	amount: {
		presence: { allowEmpty: false, message: "^Amount is required" },
		numericality: {
			greaterThan: 0,
			message: "^Amount must be a positive number",
		},
	},
	holderName: {
		presence: { allowEmpty: false, message: "^Holder name is required" },
		format: {
			pattern: "^[a-zA-Z ]+$",
			message: "^Holder name must contain only letters and spaces",
		},
	},
};

const validationConstraints = {
	offer: offerConstraints,
	signUpUser: signUpUserConstraints,
	signIn: signInConstraints,
	createBrand: createBrandConstraints,
	createBankTransfer: createBankTransferConstraints,
};

export const validateForm = (formData, formName) => {
	const constraints = validationConstraints[formName];
	let errors = validate(formData, constraints);

	if (!errors) {
		errors = {};
	}

	Object.keys(constraints).forEach((field) => {
		if (!errors[field]) {
			errors[field] = [];
		}
	});

	return errors;
};

export const validateOffer = (formData) => {
	const errors = validate(formData, validationConstraints.offer);
	return errors || {};
};
