import dayjs from "dayjs";

export const modifyCreateOffer = (offer) => {
	const modifiedOffer = {};

	const modifyArrField = (arr) => arr.map((item) => item.id);

	Object.keys(offer).forEach((key) => {
		if (key === "limitAmountEnabled" || key === "limitUsesEnabled") {
			return;
		}

		if (
			key === "limitUses" ||
			key === "limitAmountMin" ||
			key === "limitAmountMax"
		) {
			if (
				(key === "limitUses" && offer.limitUsesEnabled) ||
				(key === "limitAmountMin" && offer.limitAmountEnabled) ||
				(key === "limitAmountMax" && offer.limitAmountEnabled)
			) {
				modifiedOffer[key] = +offer[key];
			}

			return;
		}

		if (Array.isArray(offer[key])) {
			let currentArray = offer[key];

			if (key === "age") {
				// Filter All value that we added on MultiSelect for age array
				currentArray = offer[key].filter(
					(item) => item.id !== "Select Age-all",
				);
			}

			const newArr = modifyArrField(currentArray);
			modifiedOffer[key] = newArr;
			return;
		}

		if (key === "timeFrom" || key === "timeTo") {
			modifiedOffer[key] = dayjs(offer[key]).format("HH:mm:ss");
			return;
		}

		if (key === "validFrom" || key === "validTo") {
			modifiedOffer[key] = dayjs(offer[key]).format("YYYY-MM-DD");
			return;
		}

		if (
			key !== "code" &&
			key !== "allTime" &&
			key !== "name" &&
			!isNaN(offer[key])
		) {
			modifiedOffer[key] = +offer[key];
			return;
		}

		modifiedOffer[key] = offer[key];
	});

	return modifiedOffer;
};

export const modifyEditOffer = (offer) => {
	const modifiedOffer = {};

	Object.keys(offer).forEach((key) => {
		if (key === "amountGoal") {
			modifiedOffer.budget = offer[key];
			return;
		}

		if (key === "validFrom" || key === "validTo") {
			modifiedOffer[key] = dayjs(offer[key]);
			return;
		}

		if (key === "timeFrom" || key === "timeTo") {
			modifiedOffer[key] = dayjs(offer[key], "HH:mm:ss");
			return;
		}

		if (key === "limitAmountMax") {
			const limitAmountMax = offer[key] === 0 ? "" : offer[key];
			modifiedOffer[key] = limitAmountMax;
			modifiedOffer.limitAmountEnabled = !!limitAmountMax;
			return;
		}

		if (key === "limitAmountMin") {
			const limitAmountMin = offer[key] === 0 ? "" : offer[key];
			modifiedOffer[key] = limitAmountMin;
			modifiedOffer.limitAmountEnabled = !!limitAmountMin;
			return;
		}

		if (key === "limitUses") {
			const limitUses = offer[key] === 0 ? "" : offer[key];
			modifiedOffer[key] = limitUses;
			modifiedOffer.limitUsesEnabled = !!limitUses;
			return;
		}

		modifiedOffer[key] = offer[key];
	});

	return modifiedOffer;
};
