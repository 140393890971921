import React from "react";
import { Box, Typography } from "@mui/material";
import { Icon } from "./Icon";
import { TextInputNew } from "./TextInputNew";

export const AddMIDs = ({
	MIDs,
	setMIDs,
	inputStyle = {},
	showLabelOnFirst = true,
	containerStyle = {},
}) => {
	const handleAddMID = () => {
		const newMID = { id: Date.now(), value: "" };
		setMIDs((prev) => [...prev, newMID]);
	};

	const handleChangeMID = (value, id) => {
		setMIDs((prev) =>
			prev.map((mid) => (mid.id === id ? { ...mid, value } : mid)),
		);
	};

	const handleClearMID = (id) => {
		setMIDs((prev) => prev.filter((mid) => mid.id !== id));
	};

	return (
		<Box sx={containerStyle}>
			{MIDs.map((mid, i) => (
				<TextInputNew
					key={mid.id}
					value={mid.value}
					label={showLabelOnFirst && i === 0 ? "Merchant ID" : null}
					placeholder={i === 0 && showLabelOnFirst ? null : "Merchant ID"}
					onChange={(e) => handleChangeMID(e.target.value, mid.id)}
					containerStyle={{ ...inputStyle, mb: "10px" }}
					onClear={i !== 0 ? () => handleClearMID(mid.id) : null}
				/>
			))}
			<Box
				sx={{
					"display": "flex",
					"alignItems": "center",
					"&:hover": { cursor: "pointer" },
				}}
				onClick={handleAddMID}
			>
				<Icon
					name={"plusCircle"}
					style={{
						width: "15px",
						height: "15px",
					}}
				/>
				<Typography
					sx={{
						ml: "5px",
						fontSize: "12px",
						lineHeight: "14px",
						color: "#030229",
					}}
				>
					Add MID
				</Typography>
			</Box>
		</Box>
	);
};
