import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	apiUpdateUserDetails,
	apiGetBillings,
	apiUpdateBillings,
} from "../api";
import { errorHandler } from "../utils/errorHandling";
import * as Sentry from "@sentry/react";

const settingsInitialState = {
	userDetails: null,
	balance: 0,
	billings: null,
	loading: false,
	updateBillingLoading: false,
};

export const updateUserDetails = createAsyncThunk(
	"settings/updateUserDetails",
	async (userDetails, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiUpdateUserDetails(userDetails);
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

export const fetchBillings = createAsyncThunk(
	"settings/fetchBillings",
	async (_, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			const response = await apiGetBillings();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoading(false));
		}
	},
);

export const updateBillings = createAsyncThunk(
	"settings/updateBillings",
	async ({ billings, onSuccess, onFinish }, { dispatch }) => {
		try {
			dispatch(setUpdateBillingLoading(true));
			const response = await apiUpdateBillings(billings);
			onSuccess && onSuccess();
			return response.data;
		} catch (e) {
			errorHandler(e);
		} finally {
			onFinish && onFinish();
			dispatch(setUpdateBillingLoading(false));
		}
	},
);

const settingsSlice = createSlice({
	name: "settings",
	initialState: settingsInitialState,
	reducers: {
		setLoading: (state, { payload }) => {
			state.loading = payload;
		},
		setUpdateBillingLoading: (state, { payload }) => {
			state.updateBillingLoading = payload;
		},
		resetSettings: (state) => {
			state.userDetails = null;
			state.billings = null;
			state.balance = 0;
			state.loading = false;
			state.updateBillingLoading = false;
		},
		setUserDetails: (state, { payload }) => {
			state.userDetails = payload;

			Sentry.setUser({
				id: payload.id,
				email: payload.emailDetails.email,
			});
		},
		setBalance: (state, { payload }) => {
			state.balance = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(updateUserDetails.fulfilled, (state, { payload }) => {
			state.userDetails = payload;
		});
		builder.addCase(fetchBillings.fulfilled, (state, { payload }) => {
			state.billings = payload;
		});
		builder.addCase(fetchBillings.rejected, (state) => {
			state.billings = null;
		});
		builder.addCase(updateBillings.fulfilled, (state, { payload }) => {
			state.billings = payload;
		});
	},
});

export const settingsReducer = settingsSlice.reducer;
export const {
	setLoading,
	setUpdateBillingLoading,
	resetSettings,
	setUserDetails,
	setBalance,
} = settingsSlice.actions;
