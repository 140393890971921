import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TextInputNew } from "../components/TextInputNew";
import { ModalContainer } from "./ModalContainer";
import { useDispatch, useSelector } from "react-redux";
import {
	createBrand,
	fetchCreateBrandData,
	resetCreateBrandPdf,
	resetCreateBrandPdfCertificate,
	resetCreateBrandPdfVatCertificate,
	uploadCertificate,
	uploadVatCertificate,
} from "../store/brandsSlice";
import { showSnackBar } from "../store/uiSlice";
import { LoadingButton } from "@mui/lab";
import { LogoUploader } from "components/LogoUploader";
import { MultiSelectCompetitors } from "components/MultiSelectCompetitors";
import ReorderCompetitors from "components/ReorderCompetitors";
import { FileUpload } from "components/FileUpload";
import { Select } from "components/Select";
import { validateForm } from "utils/validate";

const initialNewBrand = {
	name: "",
	domain: "",
	categoryId: "",
};

const initialErrors = {
	name: [],
	domain: [],
	categoryId: [],
};

export const CreateBrand = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		categories,
		isLoadingBrand,
		allCompetitors,
		isLoadingCertificate,
		isLoadingCreateBrandData,
		isLoadingVatCertificate,
	} = useSelector((state) => state.brands);

	const userId = useSelector((state) => state.settings.userDetails.id);

	const certificatePdfNumber = useSelector(
		(state) => state.brands.createBrandPdf.certificate,
	);
	const vatCertificatePdfNumber = useSelector(
		(state) => state.brands.createBrandPdf.vatCertificate,
	);

	const [newBrand, setNewBrand] = useState(initialNewBrand);
	const [logo, setLogo] = useState(null);

	// For commercial certificate
	const [uploadCertificateProgress, setUploadCertificateProgress] = useState(0);
	const [certificate, setCertificate] = useState(null);
	const [errorCertificate, setErrorCertificate] = useState("");

	// For VAT certificate
	const [uploadVatCertificateProgress, setUploadVatCertificateProgress] =
		useState(0);
	const [vatCertificate, setVatCertificate] = useState(null);
	const [errorVatCertificate, setErrorVatCertificate] = useState("");

	const [selectedCompetitors, setSelectedCompetitors] = useState([]);
	const [errors, setErrors] = useState(initialErrors);
	const [errorLogo, setErrorLogo] = useState("");
	const [touched, setTouched] = useState({});

	// Refs to cancel upload if the user changes file mid-upload
	const abortControllerRef = useRef(null);
	const abortControllerVatRef = useRef(null);

	const isCertificateUploading = useMemo(
		() =>
			isLoadingCertificate ||
			(uploadCertificateProgress > 0 && uploadCertificateProgress < 100),
		[isLoadingCertificate, uploadCertificateProgress],
	);

	const isVatUploading = useMemo(
		() =>
			isLoadingVatCertificate ||
			(uploadVatCertificateProgress > 0 && uploadVatCertificateProgress < 100),
		[isLoadingVatCertificate, uploadVatCertificateProgress],
	);

	const handleClose = () => {
		navigate(-1);
		setNewBrand(initialNewBrand);
		setErrors(initialErrors);
		dispatch(resetCreateBrandPdf());
	};

	/**
	 * Called when user presses "Continue" to create brand
	 */
	const handleCreate = (e) => {
		e.preventDefault();

		const isFormValid = handleSubmitValidation();
		// if user pressed on continue we will show all errors
		setTouched({ domain: true, name: true, categoryId: true });

		if (!isFormValid) return;

		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `Brand ${newBrand.name} created successfully`,
					severity: "success",
					duration: 3000,
				}),
			);
			handleClose();
		};

		dispatch(
			createBrand({
				brand: {
					...newBrand,
					picture: logo?.file ?? null,
					certificatePdf: certificatePdfNumber,
					vatCertificatePdf: vatCertificatePdfNumber,
					top5competitors: selectedCompetitors
						.map((competitor) => competitor.id)
						.join(","),
				},
				onSuccess,
			}),
		);
	};

	const handleChangeNewBrand = useCallback((e) => {
		const { name, value } = e.target;
		setTouched((prev) => ({ ...prev, [name]: true }));
		setNewBrand((prev) => ({ ...prev, [name]: value }));
	}, []);

	useEffect(() => {
		if (!categories.length && !allCompetitors.length) {
			dispatch(fetchCreateBrandData());
		}
	}, [dispatch, categories, allCompetitors]);

	const handleReorderCompetitors = (startIndex, endIndex) => {
		const reorderedCompetitors = Array.from(selectedCompetitors);
		const [removed] = reorderedCompetitors.splice(startIndex, 1);
		reorderedCompetitors.splice(endIndex, 0, removed);
		setSelectedCompetitors(reorderedCompetitors);
	};

	const handleChangeLogo = (newLogo) => {
		setLogo(newLogo);
		if (errorLogo) {
			setErrorLogo("");
		}
	};

	/**
	 * Handle commercial registration certificate
	 */
	const handleChangeCertificate = (newCertificate) => {
		setCertificate(newCertificate);
		setUploadCertificateProgress(0);

		if (!newCertificate) {
			dispatch(resetCreateBrandPdfCertificate());
			return;
		}
		if (errorCertificate) {
			setErrorCertificate("");
		}

		if (abortControllerRef.current) {
			abortControllerRef.current.abort();
		}

		const abortController = new AbortController();
		abortControllerRef.current = abortController;

		dispatch(
			uploadCertificate({
				certificate: newCertificate,
				signal: abortController.signal,
				onUploadProgress: (progress) => {
					setUploadCertificateProgress(progress);
				},
			}),
		);
	};

	/**
	 * Handle VAT registration certificate
	 */
	const handleChangeVatCertificate = (newVatCertificate) => {
		setVatCertificate(newVatCertificate);
		setUploadVatCertificateProgress(0);

		if (!newVatCertificate) {
			dispatch(resetCreateBrandPdfVatCertificate());
			return;
		}
		if (errorVatCertificate) {
			setErrorVatCertificate("");
		}

		// Cancel previous upload if any
		if (abortControllerVatRef.current) {
			abortControllerVatRef.current.abort();
		}

		const abortControllerVat = new AbortController();
		abortControllerVatRef.current = abortControllerVat;

		dispatch(
			uploadVatCertificate({
				vatCertificate: newVatCertificate,
				signal: abortControllerVat.signal,
				onUploadProgress: (progress) => {
					setUploadVatCertificateProgress(progress);
				},
			}),
		);
	};

	const handleChangeSelectedCompetitors = (newSelectedCompetitors) => {
		if (newSelectedCompetitors.length > 5) {
			return;
		}
		setSelectedCompetitors(newSelectedCompetitors);
	};

	/**
	 * Validate inputs
	 */
	const handleValidation = (brandData) => {
		const newErrors = validateForm(brandData, "createBrand");
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});
		setErrors(filteredErrors);
	};

	/**
	 * Check if all fields + files are valid
	 */
	const handleSubmitValidation = () => {
		const newErrors = validateForm(newBrand, "createBrand");
		setErrors(newErrors);

		if (!logo) {
			setErrorLogo("Please upload the brand logo");
		}

		if (!certificate) {
			setErrorCertificate(
				"Please upload the Commercial Registration Certificate",
			);
		}

		// If VAT is required, check it too:
		if (!vatCertificate) {
			setErrorVatCertificate("Please upload the VAT Registration Certificate");
		}

		const noFieldErrors = Object.keys(newErrors).every(
			(key) => !newErrors[key].length,
		);

		if (noFieldErrors && logo && certificate && vatCertificate) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		handleValidation(newBrand);
	}, [newBrand]);

	const isOverallLoading =
		isLoadingBrand || isCertificateUploading || isVatUploading;

	return (
		<ModalContainer title={`Add New Brand`} handleClose={handleClose}>
			<Box component="form" onSubmit={handleCreate}>
				{/* Brand Name + Logo */}
				<Box sx={{ mb: "24px" }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<LogoUploader
							logo={logo}
							setLogo={handleChangeLogo}
							containerStyle={{ mr: "24px", height: "70px", width: "70px" }}
							isError={errorLogo}
						/>
						<TextInputNew
							label="Brand Name"
							name="name"
							value={newBrand.name}
							onChange={handleChangeNewBrand}
							containerStyle={{ flex: 1 }}
							isError={!!errors.name.length}
						/>
					</Box>
					{[errorLogo, ...errors.name].map((error) => (
						<Box key={`name-${error}`} sx={{ display: "flex", mt: "5px" }}>
							<Typography
								sx={{
									fontSize: "14px",
									lineHeight: "17px",
									fontWeight: "500",
									color: "#EF5457",
								}}
							>
								{error}
							</Typography>
						</Box>
					))}
				</Box>

				{/* 1) Commercial Registration Certificate */}
				<FileUpload
					label="Commercial Registration Certificate"
					uploadProgress={uploadCertificateProgress}
					file={certificate}
					onChangeFile={handleChangeCertificate}
					containerStyle={{ mb: "24px" }}
					error={errorCertificate}
					abortControllerRef={abortControllerRef}
				/>

				{/* 2) VAT Registration Certificate (NEW) */}
				<FileUpload
					label="VAT Registration Certificate"
					uploadProgress={uploadVatCertificateProgress}
					file={vatCertificate}
					onChangeFile={handleChangeVatCertificate}
					containerStyle={{ mb: "24px" }}
					error={errorVatCertificate}
					abortControllerRef={abortControllerVatRef}
				/>

				<TextInputNew
					label="Brand Domain"
					name="domain"
					value={newBrand.domain}
					onChange={handleChangeNewBrand}
					containerStyle={{ mb: "24px" }}
					errors={errors.domain}
				/>

				<Select
					isLoading={isLoadingCreateBrandData}
					items={categories}
					value={newBrand.categoryId}
					onChange={handleChangeNewBrand}
					name={"categoryId"}
					label={"Brand Category"}
					errors={errors.categoryId}
				/>

				<MultiSelectCompetitors
					competitors={allCompetitors}
					name={"Top 5 Competitors"}
					isLoading={isLoadingCreateBrandData}
					selectedItems={selectedCompetitors}
					setSelectedItems={handleChangeSelectedCompetitors}
				/>

				<ReorderCompetitors
					competitors={selectedCompetitors}
					setCompetitors={setSelectedCompetitors}
					onReorder={handleReorderCompetitors}
					containerStyle={{ mt: "24px" }}
				/>

				<Box sx={{ display: "flex", justifyContent: "flex-end", mt: "24px" }}>
					<LoadingButton
						sx={{
							height: "40px",
							borderRadius: "10px",
							width: "238px",
						}}
						loading={isOverallLoading}
						variant="contained"
						type="submit"
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: "16px",
								textTransform: "none",
							}}
						>
							Continue
						</Typography>
					</LoadingButton>
				</Box>
			</Box>
		</ModalContainer>
	);
};
