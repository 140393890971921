import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
	CircularProgress,
	IconButton,
	InputLabel,
	OutlinedInput,
	Typography,
} from "@mui/material";
import { Icon } from "./Icon";

export const TextInputNew = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [inputHasValue, setInputHasValue] = useState(false);

	const handleFocus = (e) => {
		setIsFocused(true);
		props.onFocus && props.onFocus(e);
	};

	const handleBlur = (e) => {
		setIsFocused(false);
		props.onBlur && props.onBlur(e);
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const typeInput = useMemo(() => {
		if (props.password && !showPassword) {
			return "password";
		}

		return "text";
	}, [props.password, showPassword]);

	const makeAnimationStartHandler = (stateSetter) => (e) => {
		const autofilled = !!e.target?.matches("*:-webkit-autofill");
		if (e.animationName === "mui-auto-fill") {
			stateSetter(autofilled);
		}

		if (e.animationName === "mui-auto-fill-cancel") {
			stateSetter(autofilled);
		}
	};

	return (
		<Box sx={props.containerStyle}>
			<Box
				sx={{
					width: "100%",
					position: "relative",
					boxSizing: "border-box",
					height: "59px",
				}}
			>
				<InputLabel
					htmlFor={props.name}
					sx={{
						position: "absolute",
						left: "12px",
						top: "16px",
						transition: "0.3s",
						fontFamily: "Inter",
						fontSize: "18px",
						transform:
							isFocused || props.value || inputHasValue
								? "translateY(-27px) scale(0.777)"
								: "translateY(0) scale(1)",
						transformOrigin: "left top",
						fontWeight: "400",
						textShadow: isFocused
							? "0 0 0.5px #61AEA0"
							: props.value
								? "0 0 0.5px #9A9A9A"
								: "none",
						lineHeight: "27px",
						color:
							props.errors?.length || props.isError
								? "#EF5457"
								: isFocused
									? "#61AEA0"
									: "#9A9A9A",
						backgroundColor: props.disabled ? "transparent" : "white.main",
						zIndex: 2,
						pointerEvents: "none",
						padding: "0 4px",
					}}
				>
					{props.disabled && props.label && (
						<Box
							sx={{
								position: "absolute",
								width: "100%",
								backgroundColor: "#F4F4F4",
								height: "12px",
								top: "14px",
								zIndex: -1,
								left: 0,
							}}
						/> // Block with gray background behind label to hide input border
					)}
					{props.label}
				</InputLabel>
				<OutlinedInput
					id={props.name}
					name={props.name}
					value={props.value}
					onChange={props.onChange}
					onFocus={handleFocus}
					onBlur={handleBlur}
					type={typeInput}
					placeholder={props.placeholder}
					disabled={props.disabled}
					fullWidth
					autoComplete={
						props.name === "password" ? "current-password" : "email"
					}
					inputProps={{
						onAnimationStart: makeAnimationStartHandler(setInputHasValue),
					}}
					sx={{
						"height": "59px",
						"fontSize": "18px",
						"lineHeight": "27px",
						"color": "#232323",
						"paddingRight": props.units ? "30px" : 0,
						"backgroundColor": props.disabled ? "#F4F4F4" : "transparent",
						"borderRadius": "10px",
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor:
								props.errors?.length || props.isError
									? "#EF5457"
									: isFocused
										? "#61AEA0"
										: "#D9D9D9",
							borderWidth: "1.5px",
							borderRadius: "10px",
						},
						"&:hover .MuiOutlinedInput-notchedOutline": {
							borderColor:
								props.errors?.length || props.isError
									? "#EF5457"
									: isFocused
										? "#61AEA0"
										: "#D9D9D9",
						},
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "#61AEA0",
						},
						"&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
							borderColor: "#D9D9D9",
						},
						"&.Mui-disabled:hover .MuiOutlinedInput-notchedOutline": {
							borderColor: "#D9D9D9",
						},
						"&.MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor:
									props.errors?.length || props.isError ? "#EF5457" : "#D9D9D9",
							},
							"&.Mui-focused fieldset": {
								borderColor:
									props.errors?.length || props.isError ? "#EF5457" : "#61AEA0",
							},
						},
					}}
				/>
				{props.units && (
					<Box
						display="flex"
						alignItems="center"
						sx={{
							position: "absolute",
							right: "16px",
							top: 0,
							height: "100%",
						}}
					>
						<Typography sx={{ fontSize: 12 }}>{props.units}</Typography>
					</Box>
				)}
				{props.password && (
					<IconButton
						sx={{
							position: "absolute",
							right: "16px",
							top: "5.5px",
							p: "12px",
						}}
						onClick={handleTogglePasswordVisibility}
						edge="end"
					>
						<Icon
							name={showPassword ? "eye" : "eyeOff"}
							style={{
								width: "24px",
								height: "24px",
							}}
						/>
					</IconButton>
				)}
				{props.onClear && (
					<IconButton
						sx={{
							position: "absolute",
							right: "21px",
							top: "12.5px",
							p: "12px",
						}}
						onClick={props.onClear}
						edge="end"
					>
						<Icon
							name={"closeBlack"}
							style={{
								width: "10px",
								height: "10px",
							}}
						/>
					</IconButton>
				)}
				{props.endTitle && (
					<Box
						sx={{
							position: "absolute",
							right: 0,
							top: 0,
							fontSize: "12px",
							lineHeight: "59px",
							color: "#232323",
							marginRight: "16px",
						}}
					>
						{props.endTitle}
					</Box>
				)}
				{props.isLoading && (
					<Box
						sx={{
							position: "absolute",
							right: "16px",
							top: 0,
							height: "59px",
							display: "flex",
							alignItems: "center",
						}}
					>
						<CircularProgress size={"16px"} />
					</Box>
				)}
			</Box>
			{props.errors &&
				props.errors.map((error) => (
					<Box
						key={`${props.name}-${error}`}
						sx={{
							display: "flex",
							mt: "5px",
						}}
					>
						<Typography
							sx={{
								fontSize: "14px",
								lineHeight: "17px",
								fontWeight: "500",
								color: "#EF5457",
							}}
						>
							{error}
						</Typography>
					</Box>
				))}
		</Box>
	);
};
