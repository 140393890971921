import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setNewOffer as setNewOfferStore } from "../store/offersSlice";
import dayjs from "dayjs";
import { MultiSelect } from "../components/MultiSelect";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../components/TextInput";
import { DatePicker } from "../components/DatePicker";
import { TimePicker } from "../components/TimePicker";
import { ModalContainer } from "./ModalContainer";
import { Checkbox } from "../components/Checkbox";
import { validateOffer } from "../utils/validate";

const initialNewOffer = {
	name: "",
	budget: "",
	validFrom: dayjs(),
	validTo: dayjs().add(1, "month"),
	timeFrom: dayjs().startOf("day"),
	timeTo: dayjs().endOf("day"),
	discount: "",
	returnPeriod: "",
	age: [],
	typeOfClient: [],
	limitAmountMin: "",
	limitAmountMax: "",
	limitAmountEnabled: false, // Checkbox to enable/disable
	limitUses: "",
	limitUsesEnabled: false, // Checkbox to enable/disable
	allTime: true,
};

export const CreateOfferGeneral = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const catalogs = useSelector(({ offers }) => offers.catalogs);
	const newOfferStore = useSelector(({ offers }) => offers.newOffer);
	const [newOffer, setNewOffer] = useState(initialNewOffer);
	const [errors, setErrors] = useState({});

	const handleClose = () => {
		navigate(-1);
		setNewOffer(initialNewOffer);
		setErrors({});
	};

	useEffect(() => {
		if (!Object.keys(newOfferStore).length) {
			setNewOffer(initialNewOffer);
		} else {
			setNewOffer(newOfferStore);
		}
	}, [newOfferStore]);

	const handleContinue = (e) => {
		e.preventDefault();

		const formErrors = validateOffer(newOffer);

		if (!newOffer.limitAmountEnabled) {
			delete formErrors.limitAmountMax;
			delete formErrors.limitAmountMin;
		}

		if (!newOffer.limitUsesEnabled) {
			delete formErrors.limitUses;
		}
		setErrors(formErrors);

		if (Object.keys(formErrors).length > 0) {
			return;
		}

		dispatch(setNewOfferStore(newOffer));
		navigate("/offers/create-offer-client-of");
	};

	const onChangeNewOffer = useCallback(
		(e) => {
			const { name, value } = e.target;
			setNewOffer((prev) => ({ ...prev, [name]: value }));

			if (name in errors) {
				const updatedErrors = { ...errors };
				delete updatedErrors[name];

				setErrors(updatedErrors);
			}
		},
		[errors],
	);

	const handleChangeDate = (newDate, field) => {
		setNewOffer((prev) => ({ ...prev, [field]: newDate }));
	};

	if (!catalogs?.ages || !catalogs?.clientOf || !catalogs?.typeOfClient) {
		return null;
	}

	return (
		<ModalContainer title={`Create New Offer`} handleClose={handleClose}>
			<Box component="form" onSubmit={handleContinue}>
				<TextInput
					value={newOffer.name}
					onChange={onChangeNewOffer}
					label="Name"
					name="name"
					error={!!errors.name}
					helperText={errors.name?.[0]}
					type={"text"}
					units={""}
					disabled={false}
					placeholder="Enter offer name"
				/>
				<Box display="flex" columnGap={"24px"} sx={{ mb: "24px" }}>
					<DatePicker
						value={newOffer.validFrom}
						onChange={(date) => handleChangeDate(date, "validFrom")}
						name="validFrom"
						label="Start Date"
					/>
					<DatePicker
						defaultValue={dayjs()}
						value={newOffer.validTo}
						onChange={(date) => handleChangeDate(date, "validTo")}
						name="validTo"
						label="End Date"
					/>
				</Box>
				<Box display="flex" columnGap={"24px"} sx={{ mb: "6px" }}>
					<TimePicker
						value={newOffer.timeFrom}
						onChange={(time) =>
							setNewOffer((prev) => ({ ...prev, timeFrom: time }))
						}
						name="timeFrom"
						label="Start Time"
						disabled={!!newOffer.allTime}
					/>
					<TimePicker
						value={newOffer.timeTo}
						onChange={(time) =>
							setNewOffer((prev) => ({ ...prev, timeTo: time }))
						}
						name="timeTo"
						label="End Time"
						disabled={!!newOffer.allTime}
					/>
				</Box>
				<Checkbox
					disableRipple
					checked={newOffer.allTime}
					onChange={() =>
						onChangeNewOffer({
							target: {
								name: "allTime",
								value: !newOffer.allTime,
							},
						})
					}
					name="24/7"
					containerStyle={{ ml: "5px", mb: "22px" }}
				/>
				<TextInput
					value={newOffer.budget}
					onChange={onChangeNewOffer}
					type="text"
					label="Budget goal"
					name="budget"
					variant="outlined"
					fullWidth
					error={!!errors.budget}
					units={"SAR"}
					helperText={errors.budget?.[0]}
					placeholder="Enter Budget"
				/>
				<Box display="flex" columnGap={"24px"}>
					<TextInput
						value={newOffer.discount}
						onChange={onChangeNewOffer}
						type="text"
						label="Discount"
						name="discount"
						error={!!errors.discount}
						helperText={errors.discount?.[0]}
						units={"%"}
						placeholder="Enter Amount"
						containerStyle={{ width: "100%" }}
					/>
					<TextInput
						value={newOffer.returnPeriod}
						onChange={onChangeNewOffer}
						type="text"
						label="Return Period"
						name="returnPeriod"
						error={!!errors.returnPeriod}
						helperText={errors.returnPeriod?.[0]}
						units={"Day"}
						placeholder={"Enter No.of days"}
						containerStyle={{ width: "100%" }}
					/>
				</Box>
				<Typography sx={{ mb: "6px" }}>Limit Per-User</Typography>
				<Box display="flex" columnGap={"24px"}>
					<Box sx={{ width: "100%" }}>
						<Checkbox
							disableRipple
							checked={newOffer.limitAmountEnabled}
							onChange={() =>
								setNewOffer((prev) => ({
									...prev,
									limitAmountEnabled: !prev.limitAmountEnabled,
								}))
							}
							gap={5}
							iconStyle={{ marginRight: "5px" }}
							name="Amount"
							containerStyle={{ ml: "5px", mb: "8px" }}
						/>
						<Box sx={{ display: "flex", justifyContent: "space-between" }}>
							<TextInput
								value={newOffer.limitAmountMin}
								onChange={onChangeNewOffer}
								name="limitAmountMin"
								type="text"
								error={!!errors.limitAmountMin}
								helperText={errors.limitAmountMin?.[0]}
								disabled={!newOffer.limitAmountEnabled}
								units={"SAR"}
								placeholder={"Min"}
								containerStyle={{ mr: "10px" }}
							/>
							<TextInput
								value={newOffer.limitAmountMax}
								onChange={onChangeNewOffer}
								name="limitAmountMax"
								type="text"
								error={!!errors.limitAmountMax}
								helperText={errors.limitAmountMax?.[0]}
								disabled={!newOffer.limitAmountEnabled}
								units={"SAR"}
								placeholder={"Max"}
							/>
						</Box>
					</Box>
					<Box sx={{ width: "100%" }}>
						<Checkbox
							disableRipple
							checked={newOffer.limitUsesEnabled}
							onChange={() =>
								setNewOffer((prev) => ({
									...prev,
									limitUsesEnabled: !prev.limitUsesEnabled,
								}))
							}
							gap={5}
							name="Uses"
							containerStyle={{ ml: "5px", mb: "8px" }}
						/>
						<TextInput
							value={newOffer.limitUses}
							onChange={onChangeNewOffer}
							name="limitUses"
							type="text"
							error={!!errors.limitUses}
							helperText={errors.limitUses?.[0]}
							units={""}
							disabled={!newOffer.limitUsesEnabled}
							placeholder={"Enter No. of uses"}
						/>
					</Box>
				</Box>
				<Box display="flex" columnGap={"24px"}>
					<MultiSelect
						allOption
						label="Age"
						name={"Select-Age"}
						data={catalogs.ages}
						selectedItems={newOffer.age}
						setSelectedItems={(newAge) =>
							onChangeNewOffer({
								target: { name: "age", value: newAge },
							})
						}
						placeholder="Select Age"
						error={!!errors.age}
						helperText={errors.age?.[0]}
						containerStyle={{ width: "100%" }}
					/>
					<MultiSelect
						allOption
						label="Type"
						name={"Select-Type"}
						data={catalogs.typeOfClient}
						selectedItems={newOffer.typeOfClient}
						setSelectedItems={(newTypeOfClient) =>
							onChangeNewOffer({
								target: { name: "typeOfClient", value: newTypeOfClient },
							})
						}
						placeholder="Select Type"
						error={!!errors.typeOfClient}
						helperText={errors.typeOfClient?.[0]}
						containerStyle={{ width: "100%" }}
					/>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					<Button
						sx={{
							height: "45px",
							mt: "8px",
							borderRadius: "10px",
							width: "238px",
						}}
						variant="contained"
						type="submit"
					>
						<Typography
							sx={{
								fontFamily: "Nunito",
								fontWeight: "600",
								fontSize: 16,
								textTransform: "capitalize",
							}}
						>
							Continue
						</Typography>
					</Button>
				</Box>
			</Box>
		</ModalContainer>
	);
};
