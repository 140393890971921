import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { TextInputNew } from "./TextInputNew";
import { Checkbox, FormControlLabel, Link } from "@mui/material";
import { OrDivider } from "./OrDivider";
import { useSelector, useDispatch } from "react-redux";
import { checkSignUpEmail, setSignUpData, signUp } from "store/authSlice";
import { validateForm } from "utils/validate";
import { LoadingButton } from "@mui/lab";

const ERROR_EMAIL_EXISTS = "An account with this email address already exists";

export default function SignUp({ switchToSignIn, setCurrentStep }) {
	const dispatch = useDispatch();
	const { signUpData, isLoadingCheckEmail, isLoadingSignUp } = useSelector(
		(state) => state.auth,
	);
	const userId = signUpData.userId;

	// Local + format validation errors
	const [errors, setErrors] = useState({
		title: [],
		firstName: [],
		lastName: [],
		email: [],
		phone: [],
		password: [],
		confirmPassword: [],
	});

	// Separate server-side duplication error for email
	const [serverEmailError, setServerEmailError] = useState("");

	const [touched, setTouched] = useState({});

	// This tracks if the email is taken or not from the backend
	const emailChecked = signUpData.emailChecked;

	const formData = signUpData.user;

	// Handle input changes
	const handleChange = (event) => {
		let { name, value } = event.target;

		// Mark the field as touched
		setTouched((prev) => ({ ...prev, [name]: true }));

		// Ensure email is always lowercase
		if (name === "email") {
			value = value.toLowerCase();

			// If the user typed a new email string, reset emailChecked and serverEmailError
			if (value !== formData.email) {
				dispatch(
					setSignUpData({
						...signUpData,
						emailChecked: null, // Force re-check when done typing
					}),
				);
				setServerEmailError(""); // Clear duplication error only if actual email string changed
			}
		}

		// Update the form data
		dispatch(
			setSignUpData({
				...signUpData,
				user: { ...formData, [name]: value },
			}),
		);
	};

	// Debounce email check
	useEffect(() => {
		if (!formData.email) return;
		const timerId = setTimeout(() => {
			dispatch(checkSignUpEmail({ email: formData.email }));
		}, 300);

		return () => clearTimeout(timerId);
	}, [formData.email, dispatch]);

	// If the user changes the email field, check local validation
	// or if user typed the same email => do not clear server error
	const handleValidation = (form) => {
		const newErrors = validateForm(form, "signUpUser");
		// Only show errors for fields the user has touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});
		setErrors(filteredErrors);
	};

	// Run local validations whenever form data changes
	useEffect(() => {
		handleValidation(formData);
	}, [formData]);

	// Watch the BE "emailChecked" to set or clear the duplication error
	useEffect(() => {
		if (touched.email && emailChecked !== null) {
			if (!emailChecked) {
				// Email is registered -> duplication error
				setServerEmailError(ERROR_EMAIL_EXISTS);
			} else {
				// Email is free -> no error
				setServerEmailError("");
			}
		}
	}, [emailChecked, touched.email]);

	// Decide if the Continue button is disabled
	const disabledButton = useMemo(() => {
		// Check if all required fields are filled
		const allFieldsFilled = Object.keys(formData).every((field) =>
			// 'title' is optional => skip
			field === "title" ? true : formData[field].length > 0,
		);
		// Check if there's any local format error
		const isErrors = Object.keys(errors).some(
			(field) => errors[field].length !== 0,
		);
		// Must have email checked, no local errors, must accept T&C,
		// AND must not have the server duplication error
		const hasServerError = serverEmailError.length > 0;

		return (
			!allFieldsFilled ||
			!signUpData.checkedTermsConditions ||
			!emailChecked ||
			isErrors ||
			hasServerError
		);
	}, [
		formData,
		signUpData.checkedTermsConditions,
		emailChecked,
		errors,
		serverEmailError,
	]);

	// T&C
	const handleCheckboxChange = (event) => {
		dispatch(
			setSignUpData({
				...signUpData,
				checkedTermsConditions: event.target.checked,
			}),
		);
	};

	// Submit to sign up
	const handleSubmit = (event) => {
		event.preventDefault();
		const userData = { ...formData };
		delete userData.confirmPassword;

		if (userId) {
			userData.userId = userId;
		}

		const onSuccess = () => {
			setCurrentStep(1);
		};

		dispatch(signUp({ userData, onSuccess }));
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Sign up
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				Sign up to enjoy the features of Mthmr
			</Typography>

			<form onSubmit={handleSubmit}>
				{/* Title (Optional) */}
				<TextInputNew
					label="Title"
					name="title"
					value={formData.title}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.title}
				/>

				{/* First Name */}
				<TextInputNew
					label="First Name"
					name="firstName"
					value={formData.firstName}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.firstName}
				/>

				{/* Last Name */}
				<TextInputNew
					label="Last Name"
					name="lastName"
					value={formData.lastName}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.lastName}
				/>

				{/* Business Email */}
				<TextInputNew
					label="Business Email"
					name="email"
					value={formData.email}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					isLoading={isLoadingCheckEmail}
					// Merge local format errors with the serverEmailError
					errors={
						serverEmailError
							? [...errors.email, serverEmailError]
							: errors.email
					}
				/>

				{/* Phone Number */}
				<TextInputNew
					label="Phone Number"
					name="phone"
					value={formData.phone}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.phone}
				/>

				{/* Password */}
				<TextInputNew
					label="Password"
					name="password"
					password
					value={formData.password}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.password}
				/>

				{/* Confirm Password */}
				<TextInputNew
					label="Confirm Password"
					name="confirmPassword"
					password
					value={formData.confirmPassword}
					onChange={handleChange}
					errors={errors.confirmPassword}
				/>

				{/* Terms Checkbox */}
				<FormControlLabel
					sx={{ mt: "20px" }}
					control={
						<Checkbox
							checked={signUpData.checkedTermsConditions}
							onChange={handleCheckboxChange}
							color="primary"
						/>
					}
					label={
						<Typography
							sx={{
								fontFamily: "Inter",
								fontSize: "18px",
								fontWeight: "400",
								color: "#6C6C6C",
							}}
						>
							I agree to the{" "}
							<Box
								sx={{
									"color": "primary.dark",
									"position": "relative",
									"display": "inline-block",
									"::after": {
										content: '""',
										position: "absolute",
										left: 0,
										bottom: "2px",
										width: "100%",
										height: "1.3px",
										backgroundColor: "primary.dark",
									},
									"fontWeight": "600",
									"&:hover": { cursor: "pointer" },
								}}
								component="span"
							>
								<Link
									href="https://www.mthmr.com/Terms&Conditions"
									target="_blank"
									rel="noopener"
									color="primary.dark"
									style={{ textDecoration: "none" }}
								>
									Terms &amp; Conditions
								</Link>
							</Box>
						</Typography>
					}
				/>

				<LoadingButton
					type="submit"
					variant="contained"
					color="primary"
					fullWidth
					sx={{ height: "54px", mt: "20px", borderRadius: "10px", mb: "20px" }}
					loading={isLoadingSignUp}
					disabled={disabledButton}
				>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontWeight: "600",
							fontSize: "18px",
							lineHeight: "22px",
							letterSpacing: "-0.01em",
							textTransform: "none",
						}}
					>
						Continue
					</Typography>
				</LoadingButton>
			</form>

			<OrDivider containerStyle={{ mb: "32px" }} />

			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#6C6C6C",
					textAlign: "center",
				}}
			>
				{`Already have an account? `}
				<Box
					sx={{
						"fontWeight": "600",
						"position": "relative",
						"display": "inline-block",
						"::after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: "2px",
							width: "100%",
							height: "1.3px",
							backgroundColor: "primary.dark",
						},
						"color": "primary.dark",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={switchToSignIn}
					component="span"
				>
					Log in
				</Box>
			</Typography>
		</Box>
	);
}
