import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { signIn } from "../store/authSlice";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { OrDivider } from "./OrDivider";
import { TextInputNew } from "./TextInputNew";
import { validateForm } from "utils/validate";
import { useSelector } from "react-redux";

export const SignIn = ({ switchToSignUp }) => {
	const dispatch = useDispatch();
	const isLoadingSignIn = useSelector((state) => state.auth.isLoadingSignIn);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const [errors, setErrors] = useState({
		email: [],
		password: [],
	});
	const [touched, setTouched] = useState({});

	// Whenever a user types in an input, store it in our local state
	// and ensure email is always lowercase
	const handleChange = (event) => {
		let { name, value } = event.target;

		setTouched((prev) => ({ ...prev, [name]: true }));

		// Ensure email is always lowercase
		if (name === "email") {
			value = value.toLowerCase();
		}

		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Determine if the "Log in" button should be disabled
	const disabledButton = useMemo(() => {
		const emptyFields = !formData.email.length || !formData.password.length;
		const withoutErrors = Object.keys(errors).every(
			(key) => errors[key].length === 0,
		);
		return emptyFields || !withoutErrors;
	}, [errors, formData]);

	// Submit the form to sign in
	const handleSubmit = async (event) => {
		event.preventDefault();

		// If the backend returns field-specific errors (e.g., invalid email, etc.),
		// we will call onError to set those errors in local state.
		const onError = (newErrors) => {
			setErrors((prev) => ({ ...prev, ...newErrors }));
		};

		dispatch(signIn({ data: formData, onError }));
	};

	// Validate the form data whenever it changes
	const handleValidation = (form) => {
		const newErrors = validateForm(form, "signIn");

		// Only show errors for fields that have been touched
		const filteredErrors = Object.keys(newErrors).reduce((obj, key) => {
			return {
				...obj,
				[key]: touched[key] ? newErrors[key] : [],
			};
		}, {});

		setErrors(filteredErrors);
	};

	useEffect(() => {
		handleValidation(formData);
	}, [formData]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flex: 1,
				justifyContent: "center",
				px: "64px",
			}}
		>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontWeight: "700",
					fontSize: "40px",
					lineHeight: "44px",
					color: "#232323",
					letterSpacing: "-0.04em",
					mb: "12px",
				}}
			>
				Log in
			</Typography>
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#969696",
					mb: "32px",
				}}
			>
				Welcome back to Mthmr
			</Typography>
			<form onSubmit={handleSubmit}>
				<TextInputNew
					label="Email"
					name="email"
					value={formData.email}
					onChange={handleChange}
					containerStyle={{ mb: "20px" }}
					errors={errors.email}
				/>
				<TextInputNew
					label="Password"
					name="password"
					password
					value={formData.password}
					onChange={handleChange}
					errors={errors.password}
				/>
				<LoadingButton
					type="submit"
					variant="contained"
					color="primary"
					loading={isLoadingSignIn}
					fullWidth
					disabled={disabledButton}
					sx={{
						height: "54px",
						mt: "20px",
						borderRadius: "10px",
						mb: "20px",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Inter",
							fontWeight: "600",
							fontSize: "18px",
							lineHeight: "22px",
							letterSpacing: "-0.01em",
							textTransform: "none",
						}}
					>
						Log in
					</Typography>
				</LoadingButton>
			</form>
			<OrDivider containerStyle={{ mb: "32px" }} />
			<Typography
				sx={{
					fontFamily: "Inter",
					fontSize: "18px",
					lineHeight: "27px",
					color: "#6C6C6C",
					textAlign: "center",
				}}
			>
				{`Don’t have an account? `}
				<Box
					sx={{
						"fontWeight": "600",
						"position": "relative",
						"display": "inline-block",
						"::after": {
							content: '""',
							position: "absolute",
							left: 0,
							bottom: "2px",
							width: "100%",
							height: "1.3px",
							backgroundColor: "primary.dark",
						},
						"color": "primary.dark",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={switchToSignUp}
					component="span"
				>
					Sign up
				</Box>
			</Typography>
		</Box>
	);
};
