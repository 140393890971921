import React, { useMemo, useState } from "react";
import { ModalContainer } from "./ModalContainer";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { showSnackBar } from "../store/uiSlice";
import { LoadingButton } from "@mui/lab";
import { addMIDandMCC } from "store/brandsSlice";
import { AddMIDs } from "components/AddMIDs";
import { AddMCCs } from "components/AddMCCs";

export const AddMIDandMCC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const loading = useSelector((state) => state.brands.isLoadingAddMIDandMCC);

	const [MCCs, setMCCs] = useState([
		{ id: Date.now() + Math.random(), value: "" },
	]);
	const [MIDs, setMIDs] = useState([
		{ id: Date.now() + Math.random(), value: "" },
	]);

	const handleClose = () => {
		navigate(-1);
	};

	const handleAddMIDandMCC = () => {
		const onSuccess = () => {
			dispatch(
				showSnackBar({
					text: `MCCs and MIDs have been added successfully!`,
					severity: "success",
				}),
			);
			handleClose();
		};

		dispatch(
			addMIDandMCC({
				data: {
					MCCs: MCCs.filter((mcc) => mcc.value.trim() !== "").map(
						(mcc) => mcc.value,
					),
					MIDs: MIDs.filter((mid) => mid.value.trim() !== "").map(
						(mid) => mid.value,
					),
				},
				onSuccess,
			}),
		);
	};

	const disabledButton = useMemo(() => {
		const hasValidMID = MIDs.some((mid) => mid.value.trim() !== "");
		const hasValidMCC = MCCs.some((mcc) => mcc.value.trim() !== "");
		return !(hasValidMID && hasValidMCC);
	}, [MIDs, MCCs]);

	return (
		<ModalContainer
			headerStyle={{ mb: "32px" }}
			handleClose={handleClose}
			title={"One more step!"}
			description={`Add your brand MID’s and MCC’s to start creating offers`}
		>
			<AddMIDs MIDs={MIDs} setMIDs={setMIDs} containerStyle={{ mb: "32px" }} />
			<AddMCCs MCCs={MCCs} setMCCs={setMCCs} containerStyle={{ mb: "32px" }} />
			<LoadingButton
				sx={{
					height: "54px",
					borderRadius: "10px",
					width: "100%",
					mb: "30px",
				}}
				loading={loading}
				variant="contained"
				onClick={handleAddMIDandMCC}
				disabled={disabledButton}
			>
				<Typography
					sx={{
						fontFamily: "Inter",
						fontWeight: "600",
						fontSize: "18px",
						lineHeight: "22px",
						textTransform: "none",
						letterSpacing: "-0.01em",
					}}
				>
					Submit
				</Typography>
			</LoadingButton>
		</ModalContainer>
	);
};
