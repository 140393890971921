import React, { useState } from "react";
import { Box, Popover, Typography, CircularProgress } from "@mui/material";
import { Icon } from "./Icon";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedBrand } from "../store/brandsSlice";
import { useNavigate } from "react-router-dom";
import { RadioButtonCircle } from "./RadioButtonCircle";
import { resetStore } from "store/rootReducer";

const id = "switch-brand";

const RadioButtonBrand = ({ brand, selected, onClick }) => {
	return (
		<Box
			sx={{
				"display": "flex",
				"justifyContent": "space-between",
				"alignItems": "center",
				"height": "53px",
				"p": "8px 16px",
				"&:hover": {
					cursor: "pointer",
				},
			}}
			onClick={onClick}
		>
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<img
					src={brand.picture}
					style={{
						width: "37px",
						height: "37px",
						borderRadius: "100px",
						border: "1px solid #EFF0F6",
						marginRight: "16px",
					}}
				/>
				<Typography
					sx={{
						fontSize: "12px",
						fontWeight: "600",
						lineHeight: "14px",
						color: "black.main",
						marginRight: "24px",
					}}
				>
					{brand.name}
				</Typography>
			</Box>
			<RadioButtonCircle selected={selected} />
		</Box>
	);
};

export const SwitchBrand = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { brands, selectedBrand, isLoadingBrands } = useSelector(
		(state) => state.brands,
	);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeSelectedBrand = (brand) => {
		dispatch(setSelectedBrand(brand));
		handleClose();
	};

	const handleGoSettings = () => {
		navigate("/settings");
	};

	const onSignOut = () => {
		dispatch(resetStore());
	};

	return (
		<>
			<Box
				sx={{
					"position": "relative",
					"display": "flex",
					"alignItems": "center",
					"height": "46px",
					"p": "5px 16px",
					"backgroundColor": "white.main",
					"borderRadius": "10px",
					"&:hover": {
						cursor: "pointer",
					},
					"minWidth": "193px",
					"boxShadow": "0 4px 20px 0 rgba(0, 0, 0, 0.05)",
				}}
				onClick={handleOpen}
				aria-describedby={id}
			>
				{selectedBrand ? (
					<img
						src={selectedBrand.picture}
						style={{
							width: "37px",
							height: "37px",
							borderRadius: "100px",
							border: "1px solid #EFF0F6",
							marginRight: "16px",
							objectFit: "cover",
						}}
					/>
				) : isLoadingBrands ? (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "37px",
							mr: "16px",
						}}
					>
						<CircularProgress size={20} />
					</Box>
				) : null}
				<Box>
					<Typography
						sx={{
							fontSize: "10px",
							lineHeight: "12px",
							color: "black.main",
							mb: "3px",
						}}
					>
						Brand
					</Typography>
					{selectedBrand && (
						<Typography
							sx={{
								fontSize: "12px",
								fontWeight: "600",
								lineHeight: "14px",
								color: "black.main",
								marginRight: "24px",
							}}
						>
							{selectedBrand.name}
						</Typography>
					)}
				</Box>
				<Icon
					name="arrowDownSwitchBrand"
					style={{
						position: "absolute",
						right: "8px",
						width: "16px",
						height: "16px",
						transform: anchorEl ? "rotate(180deg)" : "rotate(0deg)",
						transition: "transform 0.3s ease-in-out",
					}}
				/>
			</Box>
			<Popover
				id={id}
				open={!!anchorEl}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: -7,
					horizontal: 263,
				}}
				sx={{
					"& .MuiPaper-root": {
						borderRadius: "20px",
						width: "263px",
						backgroundColor: "white.main",
						p: "24px 0 16px",
						boxShadow: "0 2px 24px 0 rgba(0, 0, 0, 0.1)",
					},
				}}
			>
				<Typography
					sx={{
						fontSize: "10px",
						lineHeight: "12px",
						color: "black.main",
						mb: "8px",
						px: "16px",
					}}
				>
					Brand
				</Typography>
				{isLoadingBrands ? (
					<Box sx={{ display: "flex", justifyContent: "center", py: "10px" }}>
						<CircularProgress size={20} />
					</Box>
				) : brands.length ? (
					brands.map((brand) => (
						<RadioButtonBrand
							key={brand.id}
							brand={brand}
							selected={brand.id === selectedBrand?.id}
							onClick={() => handleChangeSelectedBrand(brand)}
						/>
					))
				) : (
					<Typography
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							fontSize: "12px",
							fontWeight: "600",
							height: "30px",
						}}
					>
						No brands
					</Typography>
				)}
				<Box
					sx={{
						width: "100%",
						height: "1px",
						backgroundColor: "#e5e5e5",
						m: "10px 16px",
					}}
				/>
				<Box
					sx={{
						"display": "flex",
						"alignItems": "center",
						"height": "32px",
						"px": "16px",
						"&:hover": {
							cursor: "pointer",
						},
						"mb": "10px",
					}}
					onClick={handleGoSettings}
				>
					<Icon
						name="settings"
						style={{ width: "16px", height: "16px", marginRight: "10px" }}
					/>
					<Typography
						sx={{
							fontWeight: "600",
							fontSize: "12px",
							lineHeight: "14px",
							color: "#2C2C2C",
						}}
					>
						Settings
					</Typography>
				</Box>
				{/* TODO: implement in the next version */}
				{/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "32px",
            px: "16px",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={handleSupport}
        >
          <Icon
            name="support"
            style={{ width: "16px", height: "16px", marginRight: "10px" }}
          />
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "14px",
              color: "#2C2C2C",
            }}
          >
            Support
          </Typography>
        </Box> */}
				<Box
					sx={{
						width: "100%",
						height: "1px",
						backgroundColor: "#e5e5e5",
						m: "10px 16px",
					}}
				/>
				<Box
					sx={{
						"display": "flex",
						"alignItems": "center",
						"height": "32px",
						"px": "16px",
						"&:hover": {
							cursor: "pointer",
						},
					}}
					onClick={onSignOut}
				>
					<Icon
						name="signOut"
						style={{ width: "16px", height: "16px", marginRight: "10px" }}
					/>
					<Typography
						sx={{
							fontWeight: "600",
							fontSize: "12px",
							lineHeight: "14px",
							color: "#8FA0B4",
						}}
					>
						Sign out
					</Typography>
				</Box>
			</Popover>
		</>
	);
};
