import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	apiPostCheckEmail,
	apiPostResendVerificationEmail,
	apiPostSignIn,
	apiPostSignUp,
	apiPostVerifyEmail,
} from "api";
import { errorHandler } from "utils/errorHandling";
import { showSnackBar } from "./uiSlice";
import { setFirstBrand } from "./brandsSlice";
import { setUserDetails } from "./settingSlice";

const authInitialState = {
	accessToken: "",
	isLoadingCheckEmail: false,
	isLoadingSignUp: false,
	isLoadingVerifyEmail: false,
	isLoadingSignIn: false,
	signUpData: {
		user: {
			title: "",
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			password: "",
			confirmPassword: "",
		},
		userId: null,
		brand: {
			name: "",
			picture: null,
			domain: "",
			categoryId: "",
			certificatePdf: null,
			vatCertificatePdf: null,
		},
		editBrand: {},
		top5competitors: [],
		checkedTermsConditions: false,
		emailChecked: null,
		isBrandCreated: false,
	},
};

// Check if user’s email is already taken
export const checkSignUpEmail = createAsyncThunk(
	"auth/checkSignUpEmail",
	async ({ email }, { dispatch }) => {
		try {
			dispatch(setLoadingCheckEmail(true));
			const response = await apiPostCheckEmail(email);
			dispatch(setSignUpEmailChecked(response.data.emailChecked));
		} catch (e) {
			if (e?.response?.data) {
				dispatch(setSignUpEmailChecked(e.response.data.emailChecked));
			} else {
				errorHandler(e);
			}
		} finally {
			dispatch(setLoadingCheckEmail(false));
		}
	},
);

// Verify email
export const verifyEmail = createAsyncThunk(
	"auth/verifyEmail",
	async ({ data, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingVerifyEmail(true));
			const response = await apiPostVerifyEmail(data);
			dispatch(
				showSnackBar({
					text: "Email verified successfully",
					severity: "success",
					duration: 3000,
				}),
			);
			onSuccess && onSuccess(response.data.token);
			dispatch(setFirstBrand(response.data.companyBrand));
			dispatch(setUserDetails(response.data.user));
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingVerifyEmail(false));
		}
	},
);

// Resend verification email
export const resendEmail = createAsyncThunk(
	"auth/resendEmail",
	async (userId, { dispatch }) => {
		dispatch(setLoadingVerifyEmail(true));
		try {
			await apiPostResendVerificationEmail({ userId });
			dispatch(
				showSnackBar({
					text: "Email has been sent successfully",
					severity: "success",
					duration: 3000,
				}),
			);
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingVerifyEmail(false));
		}
	},
);

// Sign up user
export const signUp = createAsyncThunk(
	"auth/signUp",
	async ({ userData, onSuccess }, { dispatch }) => {
		try {
			dispatch(setLoadingSignUp(true));
			const response = await apiPostSignUp(userData);
			// If we need the newly created userId in state
			onSuccess && onSuccess();
			return response.data.userId;
		} catch (e) {
			errorHandler(e);
		} finally {
			dispatch(setLoadingSignUp(false));
		}
	},
);

// Sign in user
export const signIn = createAsyncThunk(
	"auth/signIn",
	async ({ data, onError }, { dispatch }) => {
		try {
			dispatch(setLoadingSignIn(true));
			const response = await apiPostSignIn(data);

			localStorage.setItem("accessToken", response.data.token);
			return response.data.token;
		} catch (e) {
			if (e.response?.data?.password || e.response?.data?.email) {
				onError && onError(e.response.data);
			} else {
				errorHandler(e);
			}
		} finally {
			dispatch(setLoadingSignIn(false));
		}
	},
);

export const authSlice = createSlice({
	name: "authSlice",
	initialState: authInitialState,
	reducers: {
		setSignUpData: (state, { payload }) => {
			state.signUpData = payload;
		},
		setSignUpBrandData: (state, { payload }) => {
			state.signUpData.brand = payload;
		},
		setSignUpEmailChecked: (state, { payload }) => {
			state.signUpData.emailChecked = payload;
		},
		setSignUpTop5Competitors: (state, { payload }) => {
			state.signUpData.top5competitors = payload;
		},
		setAccessToken: (state, { payload }) => {
			state.accessToken = payload;
		},
		setLoadingCheckEmail: (state, { payload }) => {
			state.isLoadingCheckEmail = payload;
		},
		setLoadingSignUp: (state, { payload }) => {
			state.isLoadingSignUp = payload;
		},
		setLoadingSignIn: (state, { payload }) => {
			state.isLoadingSignIn = payload;
		},
		setLoadingVerifyEmail: (state, { payload }) => {
			state.isLoadingVerifyEmail = payload;
		},
		setBrandCreated: (state, { payload }) => {
			state.signUpData.isBrandCreated = payload;
		},
		setEditBrand: (state, { payload }) => {
			state.signUpData.editBrand = payload;
		},
		resetAuth: (state) => {
			state.accessToken = "";
			state.isLoadingCheckEmail = false;
			state.isLoadingVerifyEmail = false;
			state.isLoadingSignUp = false;
			state.signUpData = authInitialState.signUpData;
			localStorage.removeItem("accessToken");
		},
	},
	extraReducers: (builder) => {
		builder.addCase(signUp.fulfilled, (state, { payload }) => {
			state.signUpData.userId = payload;
		});
		builder.addCase(signIn.fulfilled, (state, { payload }) => {
			state.accessToken = payload;
		});
	},
});

export const authReducer = authSlice.reducer;

export const {
	setAccessToken,
	setLoadingCheckEmail,
	setSignUpData,
	setSignUpBrandData,
	setSignUpEmailChecked,
	setSignUpTop5Competitors,
	setLoadingSignUp,
	setLoadingSignIn,
	setLoadingVerifyEmail,
	setBrandCreated,
	setEditBrand,
	resetAuth,
} = authSlice.actions;
